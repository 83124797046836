<template>
  <b-overlay
    id="text-center"
    class="text-center"
    :show="show"
    rounded="sm"
    variant="light"
    opacity="0.80"
  >
    <div class="booking">
      <div v-if="shipmentInfo">
        <header class="booking__header">
          <p class="booking__header-text">
            ENTER ALL THE INFORMATION TO SCHEDULE A PICKUP AND GENERATE SHIPPING DOCUMENTS
          </p>
        </header>
        <main class="booking__elements">
          <article class="booking__shipping-cards">
            <OriginShippingCard
              v-if="shipmentInfo"
              titleCard="Origin"
              subtitleCard="Pickup"
              :shipmentInfo="shipmentInfo"
              :checkInfoCards="checkInfoCards"
              @validateOriginCard="validateOriginCard"
            />
            <DeliveryShippingCard
              v-if="shipmentInfo"
              titleCard="Destination"
              subtitleCard="Delivery"
              :shipmentInfo="shipmentInfo"
              :checkInfoCards="checkInfoCards"
              @validateDeliveryCard="validateDeliveryCard"
            />
          </article>
          <ItemsTable
            class="booking__shipping-table"
            v-if="shipmentInfo"
            typeTable="LineItems"
            :hazmatCheck="hazmatCheck"
            :shipmentInfo="shipmentInfo"
            @validateHazmat="validateHazmat"
            @validatePackagingFields="validatePackagingFields"
          />
          <ItemsTable
            class="booking__shipping-table"
            v-if="shipmentInfo"
            typeTable="CarrierInformation"
            :shipmentInfo="shipmentInfo"
          />
          <b-alert class="booking__info-section" show variant="warning"
            >IF THIS IS NOT THE CARRIER OR SERVICE YOU WANT TO USE, PLEASE CLOSE THE BROWSER WINDOW,
            GO BACK TO YOUR EMAIL QUOTE, AND SELECT THE DESIRED CARRIER AND SERVICE</b-alert
          >
          <b-button
            class="booking__button"
            type="submit"
            size="sm"
            @click="checkAllInfo()"
            :disabled="busy"
          >
            Book <b-spinner v-if="busy" small></b-spinner>
          </b-button>
        </main>
      </div>
    </div>
    <template #overlay>
      <div class="text-center">
        <b-iconstack font-scale="5" animation="cylon">
          <b-icon stacked icon="circle" animation="fade" variant="info" scale="1.1"></b-icon>
          <b-icon
            stacked
            icon="wind"
            variant="info"
            scale="0.2"
            rotate="180"
            shift-v="-2.5"
            shift-h="-2.5"
          ></b-icon>
          <b-icon
            stacked
            icon="truck"
            variant="secondary"
            scale="0.4"
            shift-v="-2"
            shift-h="2"
          ></b-icon>
        </b-iconstack>
        <p id="cancel-label">Please wait...</p>
      </div>
    </template>
  </b-overlay>
</template>

<script>
import { mapGetters } from 'vuex';
import OriginShippingCard from './components/OriginShippingCard.vue';
import DeliveryShippingCard from './components/DeliveryShippingCard.vue';
import ItemsTable from './components/ItemsTable.vue';
import { warningMessage, bookMessage } from '../../utils/errorMessages';

export default {
  name: 'BookingMagaya',
  components: {
    OriginShippingCard,
    DeliveryShippingCard,
    ItemsTable,
  },
  data() {
    return {
      shipmentInfo: null,
      hazmatCheck: null,
      show: false,
      busy: false,
      checkHazmat: false,
      checkPackagingFields: null,
      checkInfoCards: false,
      checkOriginCard: false,
      checkDeliveryCard: false,
    };
  },
  computed: {
    ...mapGetters('booking', ['showInfoSendBooking']),
  },
  async created() {
    this.show = true;
    const token = decodeURIComponent(this.$route.params.token);
    this.shipmentInfo = await this.$store.dispatch('booking/getBookingInfo', token);
    if (this.shipmentInfo) {
      this.hazmatCheck = this.shipmentInfo.haul.is_hazmat;
      const data = {
        customerId: this.shipmentInfo.haul.customer_id,
        params: {
          'api-key': this.shipmentInfo.api_key,
        },
      };
      this.show = false;
      await this.$store.dispatch('lineItems/getPicklistsHazmat', data.params);
      this.infoSendBookigConstructor('haul_id', this.shipmentInfo.haul.id);
      this.infoSendBookigConstructor(
        'carrier_quote_id',
        this.shipmentInfo.carrier.carrier_quote_id,
      );
      if (this.$route.name === 'BookingMagaya') {
        this.infoSendBookigConstructor(
          'magaya_shipment',
          this.shipmentInfo.haul.magaya_shipment,
        );
      }
    }
    this.show = false;
  },
  methods: {
    infoSendBookigConstructor(propName, value) {
      const carrierAndHaulInfo = {};
      carrierAndHaulInfo.propName = propName;
      carrierAndHaulInfo.info = value;
      this.$store.commit('booking/setSendBooking', carrierAndHaulInfo);
    },
    async checkAllInfo() {
      this.busy = true;
      this.checkInfoCards = !this.checkInfoCards;
      setTimeout(() => {
        this.sendBooking();
      }, 1000);
    },
    validateHazmat(value) {
      this.checkHazmat = value;
    },
    validatePackagingFields(value) {
      this.checkPackagingFields = value;
    },
    validateOriginCard(value) {
      this.checkOriginCard = value;
    },
    validateDeliveryCard(value) {
      this.checkDeliveryCard = value;
    },
    async sendBooking() {
      this.busy = true;
      if (this.showInfoSendBooking.line_items_hazmat
      && this.showInfoSendBooking.line_items_hazmat.line_item_hazmat.length === 0) {
        delete this.showInfoSendBooking.line_items_hazmat;
      }
      if (!this.hazmatCheck) {
        this.checkHazmat = true;
        if (this.checkPackagingFields || this.checkPackagingFields === null) {
          this.checkPackagingFields = true;
        }
      }
      if (
        !this.checkOriginCard
        || !this.checkDeliveryCard
        || !this.checkHazmat
        || !this.checkPackagingFields
      ) {
        warningMessage('Please check the fields indicated!');
        this.busy = false;
      } else {
        const bookingInfo = {
          bookingSchema: this.showInfoSendBooking,
          api_key: this.shipmentInfo.api_key,
        };
        const bookingResponse = this.$route.name === 'BookingMagaya'
          ? await this.$store.dispatch('booking/sendBookingMagaya', bookingInfo)
          : await this.$store.dispatch('booking/sendBookingInfo', bookingInfo);
        this.busy = false;
        if (bookingResponse.status === 200) {
          bookMessage();
          this.shipmentInfo = null;
          this.$router.push({
            name: 'loadBook',
          });
        }
      }
      this.busy = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.booking {
  background-color: $color-white;
  min-height: $view-height;
  margin: 20px 15px;
  &__header {
    text-align: start;
  }
  &__header-text {
    font-weight: 600;
    color: $color-primary-company;
  }
  &__elements {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    border: 1px solid $color-secondary-button;
    border-radius: 15px;
    background-color: $color-background-light;
    padding: 20px 0;
  }
  &__shipping-cards {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 90%;
    margin: auto;
    align-items: center;
  }
  &__info-section {
    margin: 10px auto;
    width: 90%;
    font-size: 18px;
    font-weight: 500;
  }
  &__subtitle {
    font-weight: 700;
    text-align: start;
    color: $color-background-dark;
  }
  &__info-section-checkbox {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    &--text {
      margin-left: 10px;
      cursor: pointer;
      &:hover {
        border-bottom: 1px solid $color-background-dark;
      }
    }
  }
  &__info-section-carrier {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
    color: $color-background-dark;
  }
  &__shipping-table {
    width: 90%;
  }
  &__button {
    background-color: $color-primary-company;
    border-color: $color-primary-company;
    border-radius: 8px;
    font-weight: 600;
    margin-top: 15px;
    padding: 5px;
    width: 20%;
    &:hover {
      background-color: $color-text-light !important;
      border-color: $color-text-light !important;
    }
    &:active {
      background-color: $color-text-light !important;
      border-color: $color-text-light !important;
    }
  }
}
.booking-load-booked {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  &__container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    border: 1px solid $color-primary-company;
    border-radius: 15px;
    background-color: $color-background-light;
    padding: 20px 20px;
    color: $color-background-dark;
    width: 75%;
  }
  &__subtitle {
    color: $color-text-light;
    font-weight: 600;
    margin: 20px 0px;
  }
}

@media (max-width: 650px) {
  .booking__shipping-cards {
    flex-direction: column;
    width: 100%;
  }
}

:deep(.alert-warning) {
  border-color: #856404;
}
</style>
