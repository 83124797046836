import { render, staticRenderFns } from "./BookingMagaya.vue?vue&type=template&id=06e2a294&scoped=true&"
import script from "./BookingMagaya.vue?vue&type=script&lang=js&"
export * from "./BookingMagaya.vue?vue&type=script&lang=js&"
import style0 from "./BookingMagaya.vue?vue&type=style&index=0&id=06e2a294&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "06e2a294",
  null
  
)

export default component.exports